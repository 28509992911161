/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Ajax Gallery
        $(document).ready(function() {
          $('.img-1 img').trigger('click');
        });
        $('.gallery-thumb img').on('click', function() {
          var currImg = $(this).attr('src');
          var theImage = '<img src="'+currImg+'">';
          $(this).parent().addClass('active');
          $(this).parent().siblings().removeClass('active');
          $('#gallery-large').html(theImage);
        });
        // Mobile Navigation
        var mobNavItem = $('.mobile-nav-show li.menu-item-has-children a');
        $(mobNavItem).on('click', function() {
          console.log("HEY");
          $(this).parent().toggleClass('active');
          $(this).parent().siblings().removeClass('active');
          $(this).parent().siblings().children('.dropdown-menu').removeClass('sub-show');
          $(this).parent().children('.dropdown-menu').toggleClass('sub-show');
        });
        $('.navbar-toggler-icon').on('click', function() {
          $('.mobile-drawer').toggleClass('show');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.hero-slider').slick();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'recipes': {
      init: function() {
        $('#filterImages').change(function(){
          $('.recipe-mas').addClass('box-hide');
          var filter = $('#filterImages');
          $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST
            beforeSend:function(xhr){
              // $('#loader').addClass('loader-active'); 
            },
            success:function(data){
              $('#gallery-box').html(data); // insert data
              // $('#loader').removeClass('loader-active'); 
            }
          });
          return false;
        });
        $(document).ready(function() {

         var filter = $('#filterImages'); 
         $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST
            beforeSend:function(xhr){
              // $('#loader').addClass('loader-active'); 
            },
            success:function(data){
              $('#gallery-box').html(data); // insert data
              // $('#loader').removeClass('loader-active'); 
            }
          });   

          $('#filter-1').parent().trigger('click');
          $('#filter-1').prop('checked', true);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
